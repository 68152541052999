import dayjs from 'dayjs'

import { Raven } from '..'
import { checkPageName, getNestedField, isServer } from '@/utils'
import { getUserAuthValues, isUserSignedIn } from '@/utils/general/user'
import { RAVEN_COMMON_KEYS, RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '../ravenConstants'
import { SUGGESTION_TYPE, categoryType } from '@/constants/pages/home'
import { getStatsigExperimentsFromClientSideCookie } from '@/utils/experiment/statsig'
import { isEmpty } from '@/utils/general/browserHelper'
import { getDateInYearMonthDayFormat, secondsToDateString } from '@/utils/general/date'
import { DOMAIN } from '@/utils/general/environment'
import baseFetch from '@/network/baseFetch'
import { REQUEST_METHODS } from '@/constants/network'

export const lowerCaseRavenData = (data: Record<string, unknown>) => {
    const trackingObject: Record<string, unknown> = {}
    for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'string') {
            trackingObject[key] = value.toLowerCase()
        } else if (!Array.isArray(value) && typeof value === 'object' && value !== null) {
            trackingObject[key] = lowerCaseRavenData(value as Record<string, unknown>)
        } else {
            trackingObject[key] = value
        }
    }

    return trackingObject
}

export const getStatstigData = () => {
    if (isServer()) {
        return {}
    }

    const statstigCookies = getStatsigExperimentsFromClientSideCookie()
    const cookieStrings = Object.keys(statstigCookies)
        .map(key => {
            if (key && statstigCookies[key]) {
                return `${key}:${statstigCookies[key]}`
            }

            return ''
        })
        .filter(value => !!value)
        .join('|')

    return {
        [RAVEN_COMMON_KEYS.STATSTIG_AB_KEY]: cookieStrings
    }
}

export const getRavenCommonPayload = () => {
    const platform = 'desktop'
    const vertical = 'air'
    const isLoggedIn = isUserSignedIn() ? 'Yes' : 'No'

    const payload = {
        [RAVEN_COMMON_KEYS.DOMAIN]: getNestedField(['location', 'hostname'], window),
        [RAVEN_COMMON_KEYS.PLATFORM]: platform,
        [RAVEN_COMMON_KEYS.VERTICAL]: vertical,
        [RAVEN_COMMON_KEYS.LOGIN_STATUS]: isLoggedIn,
        ...getStatstigData()
    }

    return payload
}

export const overrideRavenData = () => {
    const payload = {
        [RAVEN_COMMON_KEYS.CURRENCY]: 'INR'
    }

    return payload
}

/**
 *
 * @param date - Date string in the format of DD/MM/YYYY
 * @returns - Date string in the format of MM/DD/YYYY
 */
export const formatDate = (date: string) => {
    if (!date) return ''
    const splitDate = date.split('/')

    return `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`
}
export function userLoyalityAttributesPayload(marTechData, eventName) {
    const { lifetimeBooking = 'na', postmergerBooking = 'na', lastOneYearBooking = 'na',firstbookingDate='na',lastbookingDate='na',secondLastbookingDate='na' } = marTechData?.bookingStatus || {}
    const loyaltyStatusProgram = marTechData?.loyaltyStatus?.reduce((acc, item) => {
      const key = Object.keys(item)?.[0]
      if (key) {
        acc[key] = item?.[key]?.program
      }
      return acc
    }, {})

    const loyaltyStatus= marTechData?.loyaltyStatus?.reduce((acc, loyalty) => {
        const key = Object.keys(loyalty)[0];
        acc[`${key}LoyaltyStartDt`] = loyalty[key].loyaltyStartDate || 'na';
        acc[`${key}LoyaltyEndDt`] = loyalty[key].loyaltyEndDate || 'na';
        return acc;
         }, {});
    
  
    let bookingCountAttributes = {
      ct_lifetime_booking: lifetimeBooking,
      ct_postmerger_booking: postmergerBooking,
      ct_last_1year_booking: lastOneYearBooking,
      ct_first_booking_dt: secondsToDateString(firstbookingDate),
      ct_2nd_last_booking_dt:secondsToDateString(secondLastbookingDate) ,
      ct_last_booking_dt: secondsToDateString(lastbookingDate)
    }
    if (eventName === 'a_purchase' && !marTechData?.isPurchaseEventTrigger) {
      bookingCountAttributes = {
        ...bookingCountAttributes,
        ct_lifetime_booking: bookingCountAttributes['ct_lifetime_booking'] + 1 || 'na',
        ct_postmerger_booking: bookingCountAttributes['ct_postmerger_booking'] + 1 || 'na',
        ct_last_1year_booking: bookingCountAttributes['ct_last_1year_booking'] + 1 || 'na',
        ct_last_booking_dt: getDateInYearMonthDayFormat() || 'na'
      }
    }
    const loyalityAttributes = {
      fk_loyalty_status: loyaltyStatusProgram?.['fk'] || 'na',
      myntra_loyalty_status: loyaltyStatusProgram?.['myntra'] || 'na',
      fk_loyalty_start_dt: secondsToDateString(loyaltyStatus?.fkLoyaltyStartDt) || 'na',
      fk_loyalty_end_dt: secondsToDateString(loyaltyStatus?.fkLoyaltyEndDt) || 'na',
      myntra_loyalty_end_dt: secondsToDateString(loyaltyStatus?.myntraLoyaltyEndDt) || 'na',
      myntra_loyalty_start_dt: secondsToDateString(loyaltyStatus?.myntraLoyaltyStartDt) || 'na'
    }
  
    const sessionPayload = {
      ...marTechData,
      bookingStatus: {
        ...marTechData?.bookingStatus,
        lifetimeBooking: bookingCountAttributes['ct_lifetime_booking'],
        postmergerBooking: bookingCountAttributes['ct_postmerger_booking'],
        lastOneYearBooking: bookingCountAttributes['ct_last_1year_booking'],
        ct_first_booking_dt: bookingCountAttributes['ct_first_booking_dt'],
        ct_2nd_last_booking_dt: bookingCountAttributes['ct_2nd_last_booking_dt'],
        ct_last_booking_dt: bookingCountAttributes['ct_last_booking_dt']
      },
      isPurchaseEventTrigger: eventName === 'a_purchase'
    }
    const ravenMartechPayload = {
      ...loyalityAttributes,
      ...bookingCountAttributes
    }
    return { sessionPayload, ravenMartechPayload }
}
export const sendEventsWithUserProperties = (marTechUserProperties)=>{
    const loyaltyData= (marTechUserProperties?.loyaltyStatus ?? []).reduce((prev, curr) => {
        return { ...prev, ...curr }
    }, {})

    if(!isEmpty(marTechUserProperties)){
        const martechAttributes = {
            ct_lifetime_booking: (
                marTechUserProperties?.bookingStatus?.lifetimeBooking ?? 'na'
            ).toString(),
            ct_postmerger_booking: (
                marTechUserProperties?.bookingStatus?.postmergerBooking ?? 'na'
            ).toString(),
            ct_last_1year_booking: (
                marTechUserProperties?.bookingStatus?.lastOneYearBooking ?? 'na'
            ).toString(),
            ct_first_booking_dt: secondsToDateString(
                marTechUserProperties?.bookingStatus?.firstbookingDate,
            ) || 'na',
            ct_last_booking_dt: secondsToDateString(
                marTechUserProperties?.bookingStatus?.lastbookingDate,
            ) || 'na',
            ct_2nd_last_booking_dt: secondsToDateString(
                marTechUserProperties?.bookingStatus?.secondLastbookingDate,
            ) || 'na',
            fk_loyalty_status: loyaltyData?.fk?.program || 'na',
            myntra_loyalty_status: loyaltyData?.myntra?.program || 'na',
            fk_loyalty_end_dt: secondsToDateString(
                loyaltyData?.fk?.loyaltyEndDate,
            ) || 'na',
            fk_loyalty_start_dt: secondsToDateString(
                loyaltyData?.fk?.loyaltyStartDate,
            ) || 'na',
            myntra_loyalty_start_dt: secondsToDateString(
                loyaltyData?.myntra?.loyaltyStartDate,
            ) || 'na',
            myntra_loyalty_end_dt: secondsToDateString(
                loyaltyData?.myntra?.loyaltyEndDate,
            ) || 'na',
        };
       setMartechUserProperties(martechAttributes);
    }
}
export const setMartechUserProperties = (userProperties) => {
    if (
        isEmpty(userProperties) ||
        window?.sessionStorage?.getItem('martech_user_props_sent')
    ) {
        return;
    }

   if (window.clevertap) {
        window.clevertap.profile.push({
            Site: userProperties,
        });

        window?.sessionStorage?.setItem('martech_user_props_sent', 'true');
    }
};
export async function marTechPayload (eventName, userId){
    try {
        const marTechAttributesFromSS = typeof window?.sessionStorage !== 'undefined' && JSON.parse(window.sessionStorage.getItem('martech_user_attributes'))
        
        if(!isEmpty(marTechAttributesFromSS?.bookingStatus)){
            const marcTechAttributesData = userLoyalityAttributesPayload(marTechAttributesFromSS, eventName)
            const sessionValue = { ...marcTechAttributesData.sessionPayload }

            sendEventsWithUserProperties(marTechAttributesFromSS)
            window.sessionStorage.setItem('martech_user_attributes', JSON.stringify(sessionValue))
            return { ...marcTechAttributesData.ravenMartechPayload }
        }
        const URL = `${DOMAIN}/user-insights/user/${userId}`
        const responseObj = await  baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
           
        })

        if(responseObj?.data?.status === 200 && !isEmpty(responseObj?.data?.data?.bookingStatus)){
            const marcTechAttributesData = userLoyalityAttributesPayload(responseObj?.data?.data, eventName)
            const sessionValue = { ...marcTechAttributesData.sessionPayload }
            const marTechData=responseObj?.data?.data
      
            sendEventsWithUserProperties(marTechData)
            window.sessionStorage.setItem('martech_user_attributes', JSON.stringify(sessionValue))
            return { ...marcTechAttributesData.ravenMartechPayload }
        }
        if(!isEmpty(marTechAttributesFromSS)){
            const martechAttributes = {
                ct_lifetime_booking: (
                    marTechAttributesFromSS?.bookingStatus?.lifetimeBooking ?? 'na'
                ).toString(),
                ct_postmerger_booking: (
                    marTechAttributesFromSS?.bookingStatus?.postmergerBooking ?? 'na'
                ).toString(),
                ct_last_1year_booking: (
                    marTechAttributesFromSS?.bookingStatus?.lastOneYearBooking ?? 'na'
                ).toString(),
                ct_first_booking_dt: secondsToDateString(
                    marTechAttributesFromSS?.bookingStatus?.firstbookingDate,
                ) || 'na',
                ct_last_booking_dt: secondsToDateString(
                    marTechAttributesFromSS?.bookingStatus?.lastbookingDate,
                ) || 'na',
                ct_2nd_last_booking_dt: secondsToDateString(
                    marTechAttributesFromSS?.bookingStatus?.secondLastbookingDate,
                ) || 'na',
                fk_loyalty_status: loyaltyData?.fk?.program || 'na',
                myntra_loyalty_status: loyaltyData?.myntra?.program || 'na',
                fk_loyalty_end_dt: secondsToDateString(
                    loyaltyData?.fk?.loyaltyEndDate,
                ) || 'na',
                fk_loyalty_start_dt: secondsToDateString(
                    loyaltyData?.fk?.loyaltyStartDate,
                ) || 'na',
                myntra_loyalty_start_dt: secondsToDateString(
                    loyaltyData?.myntra?.loyaltyStartDate,
                ) || 'na',
                myntra_loyalty_end_dt: secondsToDateString(
                    loyaltyData?.myntra?.loyaltyEndDate,
                ) || 'na',
            };
           setMartechUserProperties(martechAttributes);
        }
        // If api response fail then pass default attributes in Events  
        return {}

    } catch (error) {
        console.log(error)
        
    }
}

export const triggerRavenHomePageload = async () => {
    const payload = {
        [RAVEN_COMMON_KEYS.PAGE_NAME]: RAVEN_PAGE_NAME.HOME,
    }
    const { userId } = getUserAuthValues()
    let marTechPayloadResult ={}
    if(isUserSignedIn() && userId){
        marTechPayloadResult= await marTechPayload('a_home_pageload', userId)
    }
    Raven.push({ eventName: RAVEN_EVENT_NAME.HOME_PAGELOAD, eventData: {...payload, ...marTechPayloadResult} })
}

export const triggerRavenHomeSearchClick = (data: Record<string, unknown>) => {
    const {
        travellerParams,
        suggestionType,
        destinationType,
        cityName,
        countryName,
        stateName,
        formateCheck_in,
        formateCheck_out,
        chk_in,
        chk_out
    } = data || {}

    // TODO: fix the below type
    // @ts-ignore
    const { adults = 0, childs = 0, num_rooms = 0 } = travellerParams || {}

    const searchDate = dayjs().format('DD/MM/YYYY')
    const searchDay = dayjs().format('dddd')

    const checkInDate = formateCheck_in || chk_in
    const checkOutDate = formateCheck_out || chk_out
    const checkInDay = dayjs(checkInDate as string, 'DD/MM/YYYY').format('dddd')

    const formattedCheckInDate = formatDate(checkInDate as string)
    const formattedCheckOutDate = formatDate(checkOutDate as string)

    const roomNights = dayjs(formattedCheckOutDate).diff(dayjs(formattedCheckInDate as string), 'd')
    const dx = dayjs(formattedCheckInDate).diff(dayjs(), 'd') + 1

    const destinationCategory = categoryType[`${destinationType}`] ?? categoryType.regular
    const destination = '',
        destinationCode = ''

    const payload = {
        [RAVEN_COMMON_KEYS.PAGE_NAME]: RAVEN_PAGE_NAME.HOME,
        h_search_destination: destination,
        h_search_destination_code: destinationCode,
        h_search_destination_category: suggestionType,
        h_search_destination_sub_category: destinationCategory,
        h_search_destination_city: cityName,
        h_search_destination_state: stateName,
        h_search_destination_country: countryName,
        h_checkin_date: checkInDate,
        h_checkout_date: checkOutDate,
        h_room_count: String(num_rooms),
        h_adults_count: String(adults),
        h_child_count: String(childs),
        h_room_nights: String(roomNights),
        h_dx: String(dx),
        h_checkin_day: checkInDay,
        h_search_day: searchDay,
        h_search_date: searchDate
    }

    Raven.push({ eventName: RAVEN_EVENT_NAME.HOME_SEARCH_CLICK, eventData: payload })
}

export const ravenHomeCfnrBannerClicked = () => {
    const payload = {
        [RAVEN_COMMON_KEYS.PAGE_NAME]: RAVEN_PAGE_NAME.HOME,
        h_cfnr_flag: 'cfnr_true'
    }
    Raven.push({ eventName: RAVEN_EVENT_NAME.CFNR_LEARN_MORE_CLICK, eventData: payload })
}

export const loginFunnelTracking = (options: {
    action: string
    event: string
    login_new?: string
    entry_type?: string
}) => {
    const payload = {
        action_name: options.action,
        login_form: 'skippable_login',
        login_type: 'native',
        page_name: checkPageName(),
        ...(options.login_new && { login_new: options.login_new }),
        ...(options.entry_type && { entry_type: options.entry_type })
    }

    Raven.push({ eventName: options.event, eventData: payload })
}

export const triggerRavenCFNRLearnMoreClick = () => {
    const payload = {
        [RAVEN_COMMON_KEYS.PAGE_NAME]: RAVEN_PAGE_NAME.CONFIRMATION,
        h_cfnr_flag: 'cfnr_true'
    }

    Raven.push({ eventName: RAVEN_EVENT_NAME.CFNR_LEARN_MORE_CLICK, eventData: payload })
}
